import "core-js/modules/web.dom-exception.stack.js";
import cookies from 'js-cookie';
import { LOGINTOKEN, USERUUID, USERID, USERNAME } from '@/constants/common';
import { getPlatform, dispatchAppFeature, gupaoEduAuth } from '@/utils/util';
import { Toast } from 'vant';
export default {
  name: 'auth-box',
  data() {
    return {
      authing: true
    };
  },
  computed: {
    // 检索url中是否有 isgper 参数
    urlParams() {
      let linkInfos = this.$route.fullPath.split('?');
      let data = {};
      linkInfos.forEach(parmas => {
        if (parmas) {
          parmas = parmas.split('&');
          for (const item of parmas) {
            const kv = item.split('=');
            data[kv[0]] = kv[1];
          }
        }
      });
      return data;
    },
    isPC() {
      return getPlatform().isPC;
    }
  },
  mounted() {
    this.authCheck();
  },
  methods: {
    // 授权检测
    async authCheck() {
      const {
        code,
        full_path
      } = this.urlParams;
      if (code) {
        // 1、先解码
        const token = window.atob(decodeURIComponent(code));
        // 2、保存到Cookies
        cookies.set(LOGINTOKEN, token);
        // 2.1、只有在app才处理
        if (getPlatform().isApp) {
          dispatchAppFeature('updatetoken', {
            token: token
          });
        }
        // 3、获取用户信息
        this.authing = true;
        const r2 = await this.$store.dispatch('User/getUserInformation');
        if (r2.code === 0) {
          var _r2$data$accountDto;
          // 存储一些登陆信息，然后跳转页面
          cookies.set(USERID, (_r2$data$accountDto = r2.data.accountDto) === null || _r2$data$accountDto === void 0 ? void 0 : _r2$data$accountDto.id);
          cookies.set(USERUUID, r2.data.uniqueId);
          cookies.set(USERNAME, r2.data.nickName);
          // 获取可能存在的回跳url
          const query = {
            ...this.urlParams
          };
          if (query.full_path) delete query.full_path;
          if (query.code) delete query.code;
          this.$router.replace({
            path: decodeURIComponent(full_path) || '/',
            query: query
          });
        } else {
          Toast({
            message: r2.msg || '用户信息获取失败'
          });
        }
        this.authing = false;
      } else {
        Toast({
          message: 'token不存在'
        });
        this.authing = false;
      }
    },
    // 授权
    authClick() {
      gupaoEduAuth('/');
    }
  }
};